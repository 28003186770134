<template>
  <section class="modal--container auth--container otp--container">
    <div class="title--text">Verifikasi Akun</div>
    <div class="desc">Mohon periksa email/SMS Anda untuk mendapatkan kode OTP</div>
    <div class="timer">05:00</div>
    <div class="otp--input">
      <input type="number" class="mr-4" v-model="otp1" :class="isFilled(otp1) ? 'filled' : ''" />
      <input type="number" class="mr-4" v-model="otp2" :class="isFilled(otp2) ? 'filled' : ''" />
      <input type="number" class="mr-4" v-model="otp3" :class="isFilled(otp3) ? 'filled' : ''" />
      <input type="number" v-model="otp4" :class="isFilled(otp4) ? 'filled' : ''" />
    </div>
    <div class="auth--link">Resend</div>
    <div class="btn btn-primary main--btn">Verifikasi</div>
  </section>
</template>

<script>
export default {
  data: () => ({
    otp1: null,
    otp2: null,
    otp3: null,
    otp4: null,
  }),
  methods: {
    isFilled(value) {
      return value !== null && value !== '';
    },
  },
  mounted() {},
};
</script>
